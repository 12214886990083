<template>
  <div class="w-result" v-if="!isLoading">
    <div class="w-result__head">
      <button @click="onBack" class="w-result__back-btn">
        <img
          src="@/static/settings/arrow.svg"
          alt="Arrow back"
          title="Previous question"
        />
      </button>
      <span class="w-result__top-title" v-if="from === 'profile'">Profile</span>
      <span class="w-result__top-title" v-else>{{ getTitleDate }}</span>
      <button class="w-result__back-btn" @click="onDelete">
        <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 3.5H3v20h16v-20zM15 .5H7v3h8v-3zM.5 3.5h22M7 7v12M11 7v12M15 7v12" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </button>
    </div>
    <div class="w-result__top-section">
      <h3 class="w-result__title">{{ workbook.name }}</h3>
      <p class="w-result__description">{{ getDate }}</p>
      <button
        @click="onRedo"
        class="quiz-btn btn-reset quiz-btn--white w-result__btn"
      >
        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_618_685)" stroke="#134E5E" stroke-linecap="round" stroke-linejoin="round"><path d="M21.523 6.64l-2.87 4.276-3.347-3.8"/><path d="M9.565 18.525a9.131 9.131 0 01-5.048-1.521 9.038 9.038 0 01-3.347-4.05 8.968 8.968 0 01-.517-5.215A9.006 9.006 0 013.14 3.118 9.106 9.106 0 017.793.648a9.145 9.145 0 015.25.514 9.074 9.074 0 014.078 3.324A8.98 8.98 0 0118.652 9.5v1.415"/></g><defs><clipPath id="clip0_618_685"><path fill="#fff" transform="matrix(-1 0 0 1 22 0)" d="M0 0h22v19H0z"/></clipPath></defs></svg>
        <span>Redo Workbook</span>
      </button>
    </div>
    <div
      class="w-result__divider first"
      :class="{ 'one-line': !isDoubleLine }"
    ></div>
    <div class="w-result__wrapper">
      <div class="w-result__results" :class="{ 'one-line': !isDoubleLine }">
        <div v-if="isExposureHierarchy" class="w-result__list">
          <div
            class="w-result__item"
            v-for="(item, index) in questions"
            :key="item.id"
          >
            <div class="w-result__item__left">
              <span class="w-result__index">{{ index + 1 }}.</span>
              <span>{{ item.description }}</span>
            </div>
            <span class="w-result__point" v-if="item.answer">{{
              item.answer.rate_answer
            }}</span>
          </div>
        </div>
        <div class="w-result__result" v-else-if="isMonitoringMoods">
          <p class="w-result__result__label" v-if="monitoringMoods.main">
            {{ monitoringMoods.main.answer_type }}:
          </p>
          <div
            class="w-result__result__specific-text"
            v-if="monitoringMoods.main && monitoringMoods.main.answer"
          >
            <span>{{ monitoringMoods.main.answer.single_answer }}</span>
          </div>
          <div class="w-result__mood-card">
            <h4 class="w-result__mood__title">Emotions:</h4>
            <div class="w-result__mood__cols">
              <div
                class="w-result__mood__col"
                v-for="item in monitoringMoods.emotions"
                :key="item.id"
              >
                <span>{{ item.group }}</span>
                <a-progress
                  :percent="item.answer.rate_answer"
                  :showInfo="false"
                />
              </div>
            </div>
            <h4 class="w-result__mood__title">Physical sensations</h4>
            <div class="w-result__mood__cols two">
              <div
                class="w-result__mood__col2"
                v-for="item in monitoringMoods.ph_sensations"
                :key="item.id"
              >
                <h5 class="w-result__mood__sub-title">{{ item.group }}:</h5>
                <p class="w-result__mood__p">
                  {{ item.answer.multiple_answer.join(', ') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-result__result" v-else-if="isThoughtRecord">
          <div class="w-result__mood-card">
            <div
              class="w-result__mood__cols two no-padding"
              v-if="thoughtRecord.main"
            >
              <div class="w-result__mood__col2">
                <h5 class="w-result__mood__sub-title p-12">
                  Describe a difficult situation:
                </h5>
                <p class="w-result__mood__p">
                  {{ thoughtRecord.main.answer.single_answer }}
                </p>
              </div>
            </div>
          </div>
          <div class="w-result__mood-card">
            <div
              class="w-result__mood__cols no-top"
              v-if="thoughtRecord.thoughts"
            >
              <div class="w-result__mood__col2">
                <h5 class="w-result__mood__sub-title p-12">
                  Automatic thoughts:
                </h5>
                <p
                  class="w-result__mood__p"
                  v-for="item in thoughtRecord.thoughts"
                  :key="item"
                >
                  {{ item.answer.multiple_answer.join(', ') }}
                </p>
              </div>
            </div>
            <h4 class="w-result__mood__title title">Emotions:</h4>
            <div class="w-result__mood__cols two no-margin">
              <div
                class="w-result__mood__col"
                v-for="item in thoughtRecord.range"
                :key="item.id"
              >
                <span>{{ item.group }}</span>
                <a-progress
                  :percent="item.answer.rate_answer"
                  :showInfo="false"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-result__result" v-else-if="isThoughtRecordReview">
          <div class="w-result__mood-card">
            <div
              class="w-result__mood__cols2 no-top"
              v-if="thoughtRecordReview.main"
            >
              <div class="w-result__mood__col2">
                <h5 class="w-result__mood__title mb-16">Thought Record</h5>
                <p class="w-result__mood__p">
                  {{ thoughtRecordReview.main.answer.single_answer }}
                </p>
              </div>
            </div>
          </div>
          <div class="w-result__mood-card">
            <div
              class="w-result__mood__cols2 no-top"
              v-if="thoughtRecordReview.thoughts"
            >
              <template
                v-for="item in thoughtRecordReview.thoughts"
                :key="item.id"
              >
                <div
                  class="w-result__mood__col2 range"
                  v-if="item.type === workbookFieldType.RANGE"
                >
                  <div class="flex jb">
                    <h5 class="w-result__mood__title m-size mb-0">
                      Likelihood
                    </h5>
                    <a-slider
                      class="slider"
                      :value="item.answer.rate_answer"
                      :tooltipVisible="false"
                    />
                  </div>
                </div>
                <div class="w-result__mood__col2" v-else>
                  <h5 class="w-result__mood__title mb-12">
                    {{ item.description }}
                  </h5>
                  <p class="w-result__mood__p">
                    {{ item.answer.single_answer }}
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="w-result__result" v-else-if="isThoughtChainEffect">
          <p class="w-result__result__label" v-if="thoughtChainEffect.main">
            Thought Record:
          </p>
          <div
            class="w-result__result__specific-text"
            v-if="thoughtChainEffect.main && thoughtChainEffect.main.answer"
          >
            <span>{{ thoughtChainEffect.main.answer.single_answer }}</span>
          </div>
          <div class="w-result__mood-card">
            <div
              class="w-result__mood__cols2 no-top"
              v-if="thoughtChainEffect.thoughts"
            >
              <template
                v-for="item in thoughtChainEffect.thoughts"
                :key="item.id"
              >
                <div
                  class="w-result__mood__col2 range"
                  v-if="item.type === workbookFieldType.RANGE"
                >
                  <div class="flex jb">
                    <h5 class="w-result__mood__title m-size mb-0">
                      Likelihood
                    </h5>
                    <a-slider
                      class="slider"
                      :value="item.answer.rate_answer"
                      :tooltipVisible="false"
                    />
                  </div>
                </div>
                <div class="w-result__mood__col2" v-else>
                  <h5 class="w-result__mood__title mb-12">
                    {{ item.description }}
                  </h5>
                  <p class="w-result__mood__p">
                    {{ item.answer.single_answer }}
                  </p>
                </div>
              </template>
            </div>
          </div>
          <p class="w-result__result__label" v-if="thoughtChainEffect.last">
            Core Belief:
          </p>
          <div
            class="w-result__result__specific-text"
            v-if="thoughtChainEffect.last && thoughtChainEffect.last.answer"
          >
            <span>{{ thoughtChainEffect.last.answer.single_answer }}</span>
          </div>
        </div>
        <div class="w-result__result" v-else-if="isPleasureMasteryList">
          <div class="w-result__pleasure__card">
            <div
              class="w-result__pleasure__cols"
              v-for="item in questions"
              :key="item.id"
            >
              <h5 class="w-result__pleasure__title">{{ item.answer_type }}:</h5>
              <template v-if="item.answer">
                <p
                  class="w-result__pleasure__p"
                  v-for="ans in item.answer.multiple_answer"
                  :key="ans"
                >
                  {{ ans }}
                </p>
              </template>
            </div>
          </div>
        </div>
        <div class="w-result__result" v-else-if="isBadThoughtDestroyer">
          <p class="w-result__result__label" v-if="badThoughtDestroyer.main">
            Core Belief:
          </p>
          <div
            class="w-result__result__specific-text"
            v-if="badThoughtDestroyer.main && badThoughtDestroyer.main.answer"
          >
            <span>{{ badThoughtDestroyer.main.answer.single_answer }}</span>
          </div>
          <div class="w-result__pleasure__card">
            <div class="w-result__pleasure__cols">
              <h5 class="w-result__pleasure__title">Contraarguments:</h5>
              <template v-for="ans in badThoughtDestroyer.thoughts" :key="ans">
                <p
                  class="w-result__pleasure__p"
                  v-for="(item, i) in ans.answer.multiple_answer"
                  :key="i"
                >
                  {{ item }}
                </p>
              </template>
            </div>
          </div>
        </div>
        <div class="w-result__result" v-else-if="isCognitiveDistortions">
          <p class="w-result__result__label" v-if="cognitiveDistortions.main">
            Thought:
          </p>
          <div
            class="w-result__result__specific-text"
            v-if="cognitiveDistortions.main && cognitiveDistortions.main.answer"
          >
            <span>{{ cognitiveDistortions.main.answer.single_answer }}</span>
          </div>
          <p class="w-result__result__label">Cognitive Distortion:</p>
          <div class="w-result__result__values">
            <template
              v-for="item in cognitiveDistortions.thoughts"
              :key="item.id"
            >
              <template
                v-if="
                  [
                    workbookFieldType.MULTI_SELECT,
                    workbookFieldType.MULTI_TEXT,
                  ].includes(item.type) && item.answer
                "
              >
                <div
                  class="w-result__result__text"
                  v-for="i in item.answer.multiple_answer"
                  :key="i"
                >
                  <span> {{ i }}</span>
                </div>
              </template>
              <div
                v-else-if="item.answer && workbookFieldType.TEXT === item.type"
                class="w-result__result__specific-text"
              >
                <span>{{ item.answer.single_answer }}</span>
              </div>
              <div
                v-else-if="
                  item.answer && workbookFieldType.SELECT === item.type
                "
                class="w-result__result__text"
              >
                <span>{{ item.answer.single_answer }}</span>
              </div>
            </template>
          </div>
        </div>
        <template v-else>
          <div
            class="w-result__result"
            v-for="(i, index) in groupWorkbook"
            :key="index"
          >
            <div
              v-if="!isDoubleLine && index !== 0"
              class="w-result__divider"
            ></div>
            <h3 class="w-result__result__title">{{ i.name }}</h3>
            <template v-for="(j, jIndex) in i.items" :key="jIndex">
              <p class="w-result__result__label" v-if="j.name">{{ j.name }}:</p>
              <div class="w-result__result__values">
                <template v-for="item in j.items" :key="item">
                  <template
                    v-if="
                      [
                        workbookFieldType.MULTI_SELECT,
                        workbookFieldType.MULTI_TEXT,
                      ].includes(item.type) && item.answer
                    "
                  >
                    <div
                      class="w-result__result__text"
                      v-for="i in item.answer.multiple_answer"
                      :key="i"
                    >
                      <span> {{ i }}</span>
                    </div>
                  </template>
                  <div
                    v-else-if="
                      item.answer && workbookFieldType.TEXT === item.type
                    "
                    class="w-result__result__specific-text"
                  >
                    <span>{{ item.answer.single_answer }}</span>
                  </div>
                  <div
                    v-else-if="
                      item.answer && workbookFieldType.SELECT === item.type
                    "
                    class="w-result__result__text"
                  >
                    <span>{{ item.answer.single_answer }}</span>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div
      class="w-result__divider"
      v-if="
        !isMonitoringMoods &&
        !isThoughtRecord &&
        !isThoughtRecordReview &&
        !isPleasureMasteryList
      "
      :class="{ 'one-line': !isDoubleLine }"
    ></div>
    <div class="w-result__footer">
      <button
        @click="onFinish"
        class="quiz-btn btn-reset quiz-btn--white w-result__btn"
      >
        <span>Finish</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { workbookFieldType, workbookType } from './workbook-data'
import moment from 'moment'
import { Modal, notification } from 'ant-design-vue'
import {
  deleteUserWorkbook,
  createUserWorkbook,
} from '@/services/workbook.service'

export default {
  name: 'WorkbookResult',
  props: {
    doubleLine: {
      type: Boolean,
      default: true,
    },
    from: {
      type: String,
      default: 'profile',
    },
  },
  data() {
    return {
      workbookFieldType,
      workbookType,
    }
  },
  computed: {
    ...mapState('workbook', [
      'workbook',
      'generalIssuesForm',
      'questions',
      'isLoading',
      'answerForm',
    ]),
    getDate() {
      return moment(this.workbook.date).format('LLLL')
    },
    isExposureHierarchy() {
      return this.workbookType.EXPOSURE_HIERARCHY === this.workbook.name
    },
    isMonitoringMoods() {
      return this.workbookType.MONITORING_MOODS === this.workbook.name
    },
    isThoughtRecord() {
      return this.workbookType.THOUGHT_RECORD === this.workbook.name
    },
    isThoughtRecordReview() {
      return this.workbookType.THOUGHT_RECORD_REVIEW === this.workbook.name
    },
    isBadThoughtDestroyer() {
      return this.workbookType.BAD_THOUGHT_DESTROYER === this.workbook.name
    },
    isThoughtChainEffect() {
      return this.workbookType.THOUGHT_CHAIN_EFFECT === this.workbook.name
    },
    isCognitiveDistortions() {
      return this.workbookType.COGNITIVE_DISTORTIONS === this.workbook.name
    },
    isPleasureMasteryList() {
      return this.workbookType.PLEASURE_MASTERY_LIST === this.workbook.name
    },
    getTitleDate() {
      return moment(this.workbook.date).format('ll')
    },
    isDoubleLine() {
      const width = window.innerWidth
      return (
        width > 775 &&
        this.doubleLine &&
        this.groupWorkbook.length > 1 &&
        !this.isMonitoringMoods &&
        !this.isThoughtRecord
      )
    },
    groupWorkbook() {
      const group = []
      for (const [index, i] of this.questions.entries()) {
        if (index === 0) continue
        if (!group.includes(i.group)) {
          group.push(i.group)
        }
      }
      return group.map((i) => {
        const filteredQ = this.questions.filter((k) => k.group === i)
        const answerTypes = []
        for (const i of filteredQ) {
          if (!answerTypes.includes(i.answer_type))
            answerTypes.push(i.answer_type)
        }
        const ans = answerTypes.map((i) => {
          return {
            name: i,
            items: filteredQ.filter((j) => j.answer_type === i),
          }
        })
        return {
          name: i,
          items: ans,
        }
      })
    },
    monitoringMoods() {
      const filteredQuestions = this.questions.slice(2)
      return {
        main: this.questions[0],
        emotions: filteredQuestions.filter(
          (i) => i.type === this.workbookFieldType.RANGE
        ),
        ph_sensations: filteredQuestions.filter(
          (i) => i.type === this.workbookFieldType.MULTI_SELECT
        ),
      }
    },
    thoughtRecord() {
      const text = this.questions.slice(1)
      return {
        main: this.questions[0],
        thoughts: text.filter(
          (i) => i.type === this.workbookFieldType.MULTI_TEXT
        ),
        range: text.filter((i) => i.type === this.workbookFieldType.RANGE),
      }
    },
    thoughtRecordReview() {
      return {
        main: this.questions[0],
        thoughts: this.questions.slice(1),
      }
    },
    badThoughtDestroyer() {
      return {
        main: this.questions[0],
        thoughts: this.questions.slice(1),
      }
    },
    cognitiveDistortions() {
      return {
        main: this.questions[0],
        thoughts: this.questions.slice(1),
      }
    },
    thoughtChainEffect() {
      return {
        main: this.questions[0],
        thoughts: this.questions.slice(1),
        last: this.questions[this.questions.length - 1],
      }
    },
  },
  methods: {
    ...mapMutations('workbook', ['RESET_WORKBOOK']),
    onDelete() {
      const that = this
      Modal.confirm({
        title: 'Are you sure?',
        okText: 'Yes',
        onOk() {
          return deleteUserWorkbook(that.$route.params.id)
            .then((_) => {
              that.$router.push({ name: 'Worksheets' })
            })
            .catch((err) => {
              console.log(err)
            })
        },
        onCancel() {},
      })
    },
    onFinish() {
      if (this.$route.params.forReview) {
        this.onSubmit()
      } else {
        this.$router.push({ name: 'Worksheets' })
      }
    },
    async onSubmit() {
      const question = []
      for (const key in this.answerForm) {
        if (key === '0000') {
          continue
        }
        const q = this.questions.find((i) => i.id === +key)
        if (q && q.type === workbookFieldType.RANGE) {
          question.push({
            id: key,
            rate_answer: this.answerForm[key],
          })
        } else if (
          q &&
          (q.type === workbookFieldType.MULTI_SELECT ||
            q.type === workbookFieldType.MULTI_TEXT)
        ) {
          question.push({
            id: key,
            multiple_answer: this.answerForm[key].filter((i) => i),
          })
        } else if (q) {
          question.push({
            id: key,
            single_answer: this.answerForm[key],
          })
        }
      }
      try {
        await createUserWorkbook({ question })
        this.$router.push({ name: 'Worksheets' })
      } catch (err) {
        console.log(err)
        // notification.error({
        //   message: 'Something went wrong',
        //   placement: 'bottomRight',
        // })
      }
    },
    onBack() {
      this.$router.back()
    },
    onRedo() {
      this.RESET_WORKBOOK()
      this.$router.push({
        name: 'Workbook',
        params: { id: this.$route.params.id },
      })
    },
  },
}
</script>

<style lang="scss">
.w-result {
  padding: 24px 36px;
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__back-btn {
    background-color: inherit;
    border: none;
    cursor: pointer;
  }

  &__top-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
  }

  &__top-section {
    max-width: 327px;
    margin: 72px auto 0;
    text-align: center;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-white);
  }

  &__description {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-white);
    margin: 8px 0 24px;
  }

  &__divider {
    border-top: 1px solid rgba(#fff, 0.1);
    max-width: 726px;
    margin: 36px auto 0;

    &.first {
      margin: 24px auto 0;
    }

    &.one-line {
      max-width: 375px;
    }
  }

  &__results {
    display: grid;
    justify-items: center;
    justify-content: center;
    grid-template-columns: 327px 327px;
    column-gap: 72px;

    &.one-line {
      grid-template-columns: 327px;
    }

    @media (max-width: 775px) {
      grid-template-columns: 327px;
      row-gap: 36px;
    }
  }

  &__pleasure {
    &__card {
      background: rgba(#ffffff, 0.1);
      border-radius: 12px;
      padding: 16px 16px 24px;
      display: grid;
      row-gap: 24px;
      text-align: left;
      margin-top: 24px;
    }

    &__title {
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      color: var(--color-white);
      margin: 0;
    }

    &__cols {
      display: grid;
      row-gap: 12px;
    }

    &__p {
      font-size: 12px;
      line-height: 16px;
      font-weight: 300;
      color: var(--color-white);
    }
  }

  &__list {
    display: grid;
    row-gap: 16px;
    width: 100%;
    margin-top: 22px;
  }

  &__item {
    font-weight: 400;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--color-white);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__left {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 16px;
    }
  }

  &__point {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-white);
    margin-left: 10px;
  }

  &__mood-card {
    background: rgba(#ffffff, 0.08);
    border-radius: 12px;
    padding: 16px;
    margin-top: 36px;
    display: grid;
    margin-bottom: 4px;
  }

  &__mood__title {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    color: var(--color-white);

    &.m-size {
      font-weight: 500;
    }

    &.mb-16 {
      margin-bottom: 16px;
    }

    &.mb-12 {
      margin-bottom: 12px;
    }

    &.title {
      margin-bottom: 0;
      margin-top: 4px;
    }
  }

  &__mood {
    &__cols2 {
      display: grid;
      row-gap: 16px;
    }
    &__cols {
      display: grid;
      padding: 24px 0 20px;
      border-bottom: 1px solid rgba(#ffffff, 0.1);
      row-gap: 10px;
      margin-bottom: 16px;

      &.two {
        row-gap: 20px;
        padding: 24px 0 8px;
        border: none;
      }

      &.no-padding {
        padding: 0;
      }

      &.no-top {
        padding-top: 0;
      }

      &.no-margin {
        margin-bottom: 0;
        row-gap: 16px;
      }
    }

    &__sub-title {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-white);
      margin-bottom: 8px;
      text-align: left;

      &.p-12 {
        margin-bottom: 12px;
      }
    }

    &__p {
      text-align: left;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-white);
    }

    &__col2 {
      &:not(:first-child) {
        border-top: 1px solid rgba(#ffffff, 0.1);
        padding-top: 16px;
      }

      & + .range {
        border-top: 0;
        padding-top: 0;
      }
    }

    &__col {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-white);

      .ant-progress {
        width: 120px;
      }

      .ant-progress-inner {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 58px;
        height: 8px;
      }

      .ant-progress-bg {
        background-color: var(--color-white);
      }
    }
  }

  &__result {
    width: 100%;
    text-align: center;
    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      color: var(--color-white);
      margin: 36px 0 0;
    }

    &__label {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: var(--color-white);
      margin-bottom: 16px;
      margin-top: 24px;
    }

    &__values {
      display: grid;
      row-gap: 16px;
    }

    &__text {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: var(--color-white);
      background: rgba(255, 255, 255, 0.1);
      border-radius: 58px;
      padding: 15px 58px;
    }

    &__specific-text {
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: var(--color-white);
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin-top: 36px;
    margin-bottom: 12px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 327px;
    & > svg {
      margin-right: 11px;
    }

    &:hover,
    &:focus {
      svg path {
        stroke: var(--color-white);
      }
    }
  }

  .slider {
    width: 120px;
  }

  .slider .ant-slider-rail {
    height: 8px;
    border-radius: 58px;
    background: rgba(255, 255, 255, 0.1);
  }

  .slider .ant-slider-track {
    height: 8px;
    border-radius: 58px;
    background-color: var(--color-white);
  }

  .slider .ant-slider-handle {
    display: none;
  }
}

.jb {
  justify-content: space-between;
  align-items: center;
}

.mb-0 {
  margin-bottom: 0;
}
</style>
